import Swiper from 'swiper/bundle';

const sliderPlugin = () => {

    const sliderChangingBtns = document.querySelectorAll('[data-slider-change-btn]');
    const cSliders = document.querySelectorAll('[data-slider]');

    //slider changing btns logic
    for (let s = 0; s < cSliders.length; s++) {

        sliderChangingBtns[s].addEventListener('click' , () => {
            //deactivate all btns and hide all sliders
            for (let j = 0; j < cSliders.length; j++) {
                sliderChangingBtns[j].classList.remove('active');
                cSliders[j].classList.add('hidden');
            }

            //activate clicked btn and show slider
            sliderChangingBtns[s].classList.add('active');
            cSliders[s].classList.remove('hidden');
        });
    }

    //slider settings and logic
    const sliderSettings = {
        speed: 500,
        loop: false,
        spaceBetween: 20,
        // slideClass: 'container__item',

        // autoplay: {
        //     delay: 4000,
        //     disableOnInteraction: false
        // },

        breakpoints: {
            // when window width is >= 0px
            0: {
                slidesPerView: 1.3,
            },
            // when window width is >= 639px
            640: {
                slidesPerView: 2,
            },
            // when window width is >= 939px
            940: {
                slidesPerView: 3,
            },
            // when window width is >= 1229px
            1230: {
                slidesPerView: 4,
            },
            // when window width is >= 1539px
            1540: {
                slidesPerView: 5,
            },
            // when window width is >= 1819px
            1820: {
                slidesPerView: 6,
            },
            // when window width is >= 2239px
            2240: {
                slidesPerView: 7,
            },
            // when window width is >= 2559px
            2560: {
                slidesPerView: 8,
            },
            // when window width is >= 2879px
            2880: {
                slidesPerView: 9,
            },
            // when window width is >= 3199px
            3200: {
                slidesPerView: 10,
            },
            // when window width is >= 3519px
            3520: {
                slidesPerView: 11,
            },
            // when window width is >= 3839px
            3840: {
                slidesPerView: 12,
            },
            // when window width is >= 4159px
            4160: {
                slidesPerView: 13,
            },
            // when window width is >= 4479px
            4480: {
                slidesPerView: 14,
            },
            // when window width is >= 4799px
            4800: {
                slidesPerView: 15,
            },
            // when window width is >= 5119px
            5120: {
                slidesPerView: 16,
            },
        }
    }

    //slider people
    const sliderPeople = new Swiper('#slider-people', sliderSettings);

    //slider situations
    const swiper = new Swiper('#slider-popular', sliderSettings);

    //slider opinions
    const sliderOpinions = new Swiper('#slider-opinions', sliderSettings);

}

export {sliderPlugin};